import React from 'react'
import { Link } from 'gatsby'

const Layout = ({ children, logo, title }) => {
  return (
    <>
      <header className='header'>
        <Link to='/'>
          <p className='logo'>{logo}</p>
        </Link>

        <p className='title'>{title}</p>

        <div
          className='anchor-container'
          onClick={() => {
            document.getElementById('about').scrollIntoView({ behavior: 'smooth' })
          }}
        >
          <p className='about'>About</p>
        </div>
      </header>
      <main>{children}</main>
      <footer>
        <div />
        <div />
        <div />
      </footer>
    </>
  )
}

export default Layout
